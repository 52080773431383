import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {find, map, mapValues, compact} from 'lodash-es'
import {For} from 'babel-plugin-jsx-control-statements'
import useLang from '../hooks/useLang'
import ServicesList from '../components/atoms/ServicesList'
import ServiceCard from '../components/atoms/ServiceCard'
import PrimaryButton from '../components/atoms/PrimaryButton'
import Typography from '../components/atoms/Typography'
import BaseLink from '../components/atoms/BaseLink'
import SubpageHero from '../components/molecules/SubpageHero'
import Section from '../components/containers/Section'
import SubpageDescription from '../components/molecules/SubpageHero/SubpageDescription'
import SubpageSubDescription from '../components/molecules/SubpageHero/SubpageSubDescription'
import Navbar from '../components/containers/Navbar'
import AppShell from '../components/containers/AppShell'


const Services = ({data, pageContext: {lang: pageLang}}) => {
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const pageData = data.markdownRemark.frontmatter[lang]
  const allServices = map(data?.services.edges, ({node}) => node.frontmatter[lang])
  const displayedServices = compact(map(pageData.servicesList, (item) => (
    find(allServices, (service) => service.slug === item.service || service.slug.substring(3) === item.service))
  ))

  return (
    <AppShell title={pageData?.menuText} lang={lang} langToSlug={langToSlug} seo={pageData.seo}>
      <Navbar title={pageData.menuText} lang={lang} langToSlug={langToSlug} />
      <SubpageHero
          title={pageData.hero.title}
          fadedSideText={pageData.hero.sideTextNumber}
          sideText={pageData.hero.sideText}
      >
        <SubpageDescription>
          {pageData.hero.description}
        </SubpageDescription>
        <SubpageSubDescription>
          {pageData.hero.subDescription}
        </SubpageSubDescription>
      </SubpageHero>
      <main>
        <Section>
          <ServicesList>
            <For each="service" of={displayedServices} index="idx">
              <BaseLink key={service.slug} to={service.slug}>
                <ServiceCard
                    key={service.slug}
                    images={service.relation.relationImages}
                    title={service.relation.title}
                >
                  <Typography type="h3" gutter>{service.relation.title}</Typography>
                  <Typography as="p" type="div" gutter thin>{service.relation.description}</Typography>
                  <PrimaryButton as="span">
                    {service.relation.showMoreText}
                  </PrimaryButton>
                </ServiceCard>
              </BaseLink>
            </For>
          </ServicesList>
        </Section>
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query Services($id: String!, $serviceKey: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        ...ServicesPageFragment
      }
    }

    services: allMarkdownRemark(filter: {frontmatter: {cs: {templateKey: {eq: $serviceKey}}}}) {
      edges {
        node {
          frontmatter {
            ...ServicePageFragment
          }
        }
      }
    }
  }
`

Services.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default Services
