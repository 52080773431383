import PropTypes from 'prop-types'
import styled from 'styled-components'
import {media} from '../../../utils'
import Image from '../Image'
import Typography from '../Typography'


const StyledServiceCard = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 25rem;
  height: 25rem;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: center;
    transition: transform 200ms ease-in;
    z-index: -1;
    border-radius: 50%;
    background: ${({theme}) => theme.colors.lightGray};
    width: 25rem;
    height: 25rem;
    content: '';
  }

  ${media.up('mobile')(`
    margin-bottom: 5rem;

    &:hover,
    &:focus {
      &::before {
        transform: scale(1.3);
      }
    }
  `)}

  ${media.down('mobile')(`
    width: 100%;
    align-items: flex-start;
    flex-flow: column-reverse;
    height: auto;
    padding: 3rem 0;

    &::before {
      top: 3rem;
      width: 12rem;
      height: 12rem;
      left: 50%;
      transform: translateX(-50%);
    }

    h3 {
      padding-bottom: 2rem;
    }
  `)}
`

const StyledContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  transform: translateY(1rem);
  transition:
    transform 200ms ease-in,
    opacity 200ms ease-in;
  opacity: 0;
  padding: 3rem 1rem 5rem;
  width: 100%;
  height: 100%;
  text-align: center;

  ${media.up('mobile')(`
    ${StyledServiceCard}:hover &,
    ${StyledServiceCard}:focus & {
      transform: translateY(0);
      opacity: 1;
    }
  `)}

  ${media.down('mobile')(`;
    opacity: 1;
    padding: 3rem 1rem 3rem;
  `)}
`

const StyledImage = styled(Image)`
  /* Rewrites inline styles provided by gatsby-plugin-image */
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 200ms ease-in, opacity 200ms ease-in;
  opacity: 1;
  width: 25rem;
  height: 25rem;
  object-fit: contain;

  ${media.up('mobile')(`
    ${StyledServiceCard}:hover &,
    ${StyledServiceCard}:focus & {
      transform: translate(-50%, 10%) scale(0.5);
      opacity: 0;
    }
  `)}

  ${media.down('mobile')(`
    /* Rewrites inline styles provided by gatsby-plugin-image */
    display: none !important;
  `)}
`

const StyledHoveredImage = styled(Image)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 200ms ease-in, opacity 200ms ease-in;
  opacity: 0;
  width: 25rem;
  height: 25rem;
  object-fit: contain;

  ${media.up('mobile')(`
    /* Rewrites inline styles provided by gatsby-plugin-image */
    position: absolute !important;

    ${StyledServiceCard}:hover &,
    ${StyledServiceCard}:focus & {
      transform: translate(-50%, 10%) scale(0.5);
      opacity: 1;
    }
  `)}

  ${media.down('mobile')(`
    position: relative;
    opacity: 1;
    margin: 0 auto;
    width: 12rem;
    height: auto;
    top: unset;
    left: unset;
    transform: unset;
  `)}
`

const StyledTitle = styled(Typography)`
  display: flex;
  position: absolute;
  bottom: -6rem;
  align-items: center;
  transition:
    transform 200ms ease-in,
    opacity 200ms ease-in;
  opacity: 1;
  height: 4.5rem;
  text-align: center;

  ${StyledServiceCard}:hover &,
  ${StyledServiceCard}:focus & {
    transform: translateY(-1rem);
    opacity: 0;
  }


  ${media.down('mobile')(`;
    display: none;
  `)}
`

const ServiceCard = ({images, title, children, ...props}) => {
  return (
    <StyledServiceCard {...props}>
      <StyledContent className="content">
        {children}
      </StyledContent>
      <StyledImage image={images?.image} alt={images?.alt} />
      <StyledHoveredImage image={images?.hoverImage} alt={images?.hoverImageAlt} />
      <StyledTitle type="h3">{title}</StyledTitle>
    </StyledServiceCard>
  )
}

ServiceCard.propTypes = {
  images: PropTypes.object,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ServiceCard
