import PropTypes from 'prop-types'
import styled from 'styled-components'
import {media} from '../../../utils'


const StyledServiceList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(100%/4.5), 1fr));
  grid-gap: 10rem;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  padding: 0 6rem 6rem;

  ${media.down('mobile')(`
    display: block;
    padding: 0 2rem;
  `)}
`

const ServicesList = ({children, ...props}) => {
  return (
    <StyledServiceList {...props}>{children}</StyledServiceList>
  )
}

ServicesList.propTypes = {
  children: PropTypes.node,
}

export default ServicesList
